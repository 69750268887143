<template>
  <el-card shadow="never">
    <el-row>
      <el-col :span="2" class="itemText">包材条码：</el-col>
      <el-col :span="6" class="itemValue">{{ recyclableBox.boxSn }}</el-col>
      <el-col :span="2" class="itemText">包材状态：</el-col>
      <el-col :span="6" class="itemValue">{{ recyclableBox.boxStatusName }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2" class="itemText">包材编码：</el-col>
      <el-col :span="6" class="itemValue">{{ recyclableBox.packMaterialCode }}</el-col>
      <el-col :span="2" class="itemText">采购入库时间：</el-col>
      <el-col :span="6" class="itemValue">{{ recyclableBox.createTime }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2" class="itemText">报废时间：</el-col>
      <el-col :span="6" class="itemValue">{{ recyclableBox.scrapTime }}</el-col>
      <el-col :span="2" class="itemText">报废人：</el-col>
      <el-col :span="6" class="itemValue">{{ recyclableBox.scrapUserName }}</el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: "RecyclableBoxInfoComponent",
  props: {
    recyclableBox: Object
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.el-row {
  line-height: 32px;
}

.el-card {
  font-size: 12px;
  color: #606266;
  line-height: 40px;
  margin-bottom: 22px;
}

.itemText {
  text-align: right;
}

.itemValue {
  color: goldenrod;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>