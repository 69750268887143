<template>
  <div>
    <el-row style="padding-bottom: 10px;line-height: 40px">
      <el-col :span="24">
        <el-input ref="boxSn" v-model.trim="formData.boxSn" placeholder="请输入或扫包材条码" @keyup.enter.native="search" style="width: 250px" clearable></el-input>
        <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
        <MessageComponent ref="searchMessage"/>
      </el-col>
    </el-row>
    <RecyclableBoxInfoComponent :recyclable-box="recyclableBox"/>
    <div>
      <el-form :model="formData" label-width="200px">
        <el-row>
          <el-form-item label="包材状态：">
            <el-select v-model="formData.boxStatus" filterable clearable class="form-item">
              <el-option v-for="item in recyclableBoxStatusList" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="5" style="text-align: right">
            <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
          </el-col>
          <el-col :span="19">
            <MessageComponent ref="commitMessage"/>
          </el-col>
        </el-row>
      </el-form>

    </div>
  </div>
</template>

<script>
import RecyclableBoxInfoComponent from "@/views/recyclableBox/RecyclableBoxInfoComponent";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "RecyclableBoxUpdate",
  components: {MessageComponent, RecyclableBoxInfoComponent},
  data() {
    return {
      formData: {
        boxSn: '',
        boxStatus: ''
      },
      recyclableBox: {
        boxSn: '',
        boxStatusName: ''
      },
      loading: false,
    }
  },
  computed: {
    recyclableBoxStatusList() {
      return this.$store.getters.getDictList('recyclableBoxStatus') || [];
    },
  },
  methods: {
    clearData() {
      this.formData.boxSn = ''
      this.formData.boxStatus = ''
      this.recyclableBox = {}
    },
    search() {
      this.$refs.searchMessage.clear();
      if (!this.formData.boxSn) {
        return this.$refs.searchMessage.showError('请输入或扫包材条码');
      }
      this.formData.boxSn = this.formData.boxSn.toUpperCase();
      if (!this.formData.boxSn.startsWith("BOX") || this.formData.boxSn.length != 23) {
        return this.$refs.searchMessage.showError('包材条码不正确');
      }

      this.loading = true

      this.$axios.post('recyclableBox/getInfo', this.formData.boxSn).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.searchMessage.showError(res.message);
        }

        if (!res.data.recyclableBox) {
          return this.$refs.searchMessage.showError('包材条码不正确');
        }

        this.recyclableBox = res.data.recyclableBox;
        this.formData.boxStatus = this.recyclableBox.boxStatus;

        this.canCommit = true;
      }, error => {
        this.loading = false;
        return this.$refs.searchMessage.showError('查询失败，' + error.message);
      })

    },
    submit() {
      if (!this.formData.boxSn) {
        return this.$refs.searchMessage.showError('请输入或扫包材条码');
      }
      if (!this.recyclableBox || !this.recyclableBox.boxSn) {
        return this.$refs.searchMessage.showError('请先查询包材信息');
      }

      this.loading = true
      this.$axios.post('recyclableBox/update', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.searchMessage.showError(res.message);
        }
        this.$refs.searchMessage.showSuccess('提交成功');
        this.clearData();
        this.$refs.boxSn.focus()
      }, error => {
        this.loading = false;
        return this.$refs.searchMessage.showError('提交失败，' + error.message);
      })


    }
  }
}
</script>

<style scoped>
.form-item {
  width: 300px;
}
</style>